import { AsyncPipe, NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Router } from '@angular/router';
import { SelectUserComponent } from '@ih/account';
import { AdminBaseComponent } from '@ih/admin-base';
import { AnimatedSaveButtonComponent } from '@ih/animated-save-button';
import { ADMIN_FROALA_TOOLBAR } from '@ih/constants';
import { SaveChangesDialogService } from '@ih/dialogs';
import { Permission, UserRoleEnum } from '@ih/enums';
import { FroalaComponent } from '@ih/froala';
import { BaseClientConfig, MemberListItem, Person, WelcomeMessage } from '@ih/interfaces';
import { AuthService, ConfigService, LazySnackBarService, SecurityService } from '@ih/services';
import { getDefaultWelcomeMessage } from '@ih/utilities';
import { BehaviorSubject, Subject } from 'rxjs';
import { catchError, finalize, take, takeUntil, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'ih-welcome-message',
  templateUrl: './welcome-message.component.html',
  styleUrls: ['./welcome-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf,

    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,

    AdminBaseComponent,
    AnimatedSaveButtonComponent,
    FroalaComponent,
    SelectUserComponent
  ]
})
export class WelcomeMessageComponent implements OnInit {
  @HostBinding('class.ih-welcome-message') hostClass = true;
  @ViewChild('selectUser') selectUser!: SelectUserComponent;

  welcomeMessageForm = new FormGroup({
    from: new FormControl<Person | null>(null, [Validators.required]),
    subject: new FormControl<string | null>(null, [Validators.required]),
    body: new FormControl<string | null>(null, [Validators.required])
  });

  saving$ = new BehaviorSubject<boolean>(false);
  loading$ = new BehaviorSubject<boolean>(true);

  private destroy$ = new Subject<void>();

  froalaToolbar = ADMIN_FROALA_TOOLBAR;

  constructor(
    private security: SecurityService,
    private saveChangesDialog: SaveChangesDialogService,
    private http: HttpClient,
    private snackbar: LazySnackBarService,
    private config: ConfigService<BaseClientConfig>,
    private auth: AuthService,
    private router: Router
  ) {
    this.saveChangesDialog.pageTitle = 'welcome message';

    if (!this.security.can(Permission.ManageAppSettings)) {
      this.snackbar.noPermission();
      return;
    }

    this.auth.isAuthenticated$.pipe(takeUntilDestroyed()).subscribe((isAuthenticated) => {
      if (!isAuthenticated) {
        console.debug('WelcomeMessageComponent: User is not authenticated, redirecting to home page');
        this.router.navigate(['/home']);
      }
    });

    this.config.config$.pipe(take(1)).subscribe((appConfig) => {
      if (appConfig.menuOptions && !appConfig.menuOptions.welcomeMessageEnabled) {
        this.snackbar.noPermission();
      }
    });

    this.welcomeMessageForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.saveChangesDialog.setModified();
    });
  }

  ngOnInit(): void {
    this.http
      .get<WelcomeMessage>('/api/campaign/mine/settings/welcomeMessage')
      .pipe(
        withLatestFrom(this.config.config$),
        catchError((err, caught) => {
          this.snackbar
            .open('Unable to retrieve the welcome message', 'TRY AGAIN')
            .then((ref) => ref.onAction().subscribe(() => caught));

          throw err;
        })
      )
      .subscribe(([welcomeMessage, appConfig]) => {
        this.loading$.next(false);
        this.welcomeMessageForm.setValue(
          {
            ...getDefaultWelcomeMessage(appConfig),
            ...welcomeMessage
          },
          { emitEvent: false }
        );
      });
  }

  resetToDefault(): void {
    this.http
      .get<MemberListItem[]>('/api/members', {
        params: {
          defaultRoles: `[${UserRoleEnum.Owner}]`,
          limit: '1'
        }
      })
      .pipe(withLatestFrom(this.config.config$))
      .subscribe(([users, appConfig]) => {
        // Convert member list item to person
        const { campaignUserId, ...person } = users[0];

        this.welcomeMessageForm.setValue({
          ...getDefaultWelcomeMessage(appConfig),
          from: {
            ...person,
            authorId: campaignUserId
          }
        });

        this.saveChangesDialog.setModified();
        this.snackbar.open('Your welcome message has been reset to the default');

        this.selectUser.focus();
      });
  }

  save(): void {
    if (this.welcomeMessageForm.valid) {
      this.saving$.next(true);
      this.welcomeMessageForm.disable({ emitEvent: false });
      this.http
        .put('/api/campaign/mine/settings/welcomeMessage', this.welcomeMessageForm.value)
        .pipe(
          catchError((err, caught) => {
            this.snackbar
              .open('Unable to save your welcome message', 'TRY AGAIN')
              .then((ref) => ref.onAction().subscribe(() => caught));

            throw err;
          }),
          finalize(() => {
            this.saving$.next(false);
            this.welcomeMessageForm.enable({ emitEvent: false });
          })
        )
        .subscribe(() => {
          this.saveChangesDialog.setModified(false);
          this.snackbar.open('Your welcome message has been saved');
        });
    }
  }
}
