<ih-admin-base
  editorTitle="Welcome message"
  editorIconPath="M21,12.13C20.85,12.14 20.71,12.19 20.61,12.3L19.61,13.3L21.66,15.3L22.66,14.3C22.88,14.09 22.88,13.74 22.66,13.53L21.42,12.3C21.32,12.19 21.18,12.14 21.04,12.13M19.04,13.88L13,19.94V22H15.06L21.12,15.93M20,4H4A2,2 0 0,0 2,6V18A2,2 0 0,0 4,20H11V19.11L19.24,10.89C19.71,10.4 20.36,10.13 21.04,10.13C21.38,10.13 21.72,10.19 22.04,10.32V6C22.04,4.88 21.12,4 20,4M20,8L12,13L4,8V6L12,11L20,6"
  formId="welcomeMessageForm"
  [saving]="(saving$ | async)!"
>
  <div button-controls class="button-controls fx-layout-row fx-layout-align-start-center">
    <button mat-flat-button class="reset-to-default-btn fx-hide-xs" (click)="resetToDefault()">Reset to default</button>
    <ih-animated-save-button
      type="submit"
      form="welcomeMessageForm"
      [saving]="(saving$ | async)!"
      mat-flat-button
      trackClick="Welcome message save button clicked"
      class="welcome-message-save-button"
    >
      <span savingText>Saving</span>
      Save
    </ih-animated-save-button>
    <button
      class="fx-hide-gt-xs welcome-message-menu"
      aria-label="Open menu"
      mat-icon-button
      [matMenuTriggerFor]="menu"
    >
      <mat-icon>
        <svg id="dots-horizontal" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M16,12A2,2 0 0,1 18,10A2,2 0 0,1 20,12A2,2 0 0,1 18,14A2,2 0 0,1 16,12M10,12A2,2 0 0,1 12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12M4,12A2,2 0 0,1 6,10A2,2 0 0,1 8,12A2,2 0 0,1 6,14A2,2 0 0,1 4,12Z"
          />
        </svg>
      </mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item class="reset-to-default-btn" (click)="resetToDefault()">Reset to default</button>
    </mat-menu>
  </div>

  <div class="loading-container" *ngIf="loading$ | async">
    <mat-spinner></mat-spinner>
  </div>

  <form id="welcomeMessageForm" [formGroup]="welcomeMessageForm" (ngSubmit)="save()" *ngIf="!(loading$ | async)">
    <div class="form-panel section-block">
      <div class="from-and-subject-container">
        <!--From-->
        <ih-select-user #selectUser formControlName="from"></ih-select-user>

        <!--Subject-->
        <div class="message-subject-container fx-layout-row">
          <mat-form-field class="message-subject fx-flex" appearance="outline">
            <mat-label>Subject</mat-label>
            <input
              matInput
              #subjectInput
              maxlength="255"
              type="text"
              class="primary-border-color-focus"
              formControlName="subject"
            />
            <mat-hint class="fx-layout-align-end">{{ subjectInput.value?.length || 0 }}/255</mat-hint>
            <mat-error *ngIf="welcomeMessageForm.controls.subject.getError('required')"
              >Please enter a subject.</mat-error
            >
          </mat-form-field>
        </div>
      </div>

      <!--Body-->
      <div class="ih-froala-container">
        <ih-froala contentType="messages" [toolbarStickyOffset]="55" formControlName="body" [allowFileUpload]="false">
        </ih-froala>
      </div>
    </div>
  </form>
</ih-admin-base>
